<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Account</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="breadcrumb-item">Charging & Billing</li>
              <li class="breadcrumb-item">Accounting</li>
              <li class="breadcrumb-item active">Account</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <a href="javascript:void(0)" class="btn btn-info" @click="showFilter = !showFilter"><i class="fa fa-filter"></i>Filter</a>
            <div class="card-tools">
              <router-link :to="{ name: 'CBSAccountForm' }" class="btn btn-success"><i class="fas fa-plus"></i> New
              </router-link>
            </div>
          </div>
          <form @submit.prevent="getList()" method="GET" novalidate>
            <div class="card-body" v-if="showFilter">
              <div class="row">
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-3 col-form-label" for="id">ID</label>
                    <div class="col-9">
                      <input type="text" id="id" class="form-control" v-model="filter.id" placeholder="ID" />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-3 col-form-label" for="code">Client Reference Id</label>
                    <div class="col-9">
                      <input type="text" id="code" class="form-control" v-model="filter.code" placeholder="Client Reference Id" />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-3 col-form-label" for="product_id">Product-Id</label>
                    <div class="col-9">
                      <input type="text" id="product_id" class="form-control" v-model="filter.product_id" placeholder="Product-Id" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-10 offset-1">
                  <button type="submit" class="btn btn-primary m-2px">Search</button>
                  <button type="reset" class="btn btn-warning" @click="resetFilter()">
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <div class="card-body table-responsive p-0">
            <div class="col-12">
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 10px">ID</th>
                    <th>Client Reference Id</th>
                    <th>Account name</th>
                    <th>Description</th>
                    <th>General Ledger Account</th>
                    <th>Parent Account</th>
                    <th>Product-Id</th>
                    <th>Status</th>
                    <th>Enable payment</th>
                    <th>System Account</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Net</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody class="CBSAccount-list">
                  <tr v-for="(item, index) in list.data" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ item.code }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.general_ledger_account_id }}</td>
                    <td>{{ item.parent_account_id }}</td>
                    <td>{{ item.product_id }}</td>
                    <td>{{ item.status }}</td>
                    <td>{{ item.enable_payments }}</td>
                    <td>{{ item.system_account }}</td>
                    <td>{{ parseInt(item.debit_balance).toFixed(2) }}</td>
                    <td>{{ parseInt(item.credit_balance).toFixed(2) }}</td>
                    <td>{{ parseInt(item.net_balance).toFixed(2) }}</td>
                    <td class="text-right">
                      <router-link :to="{
                          name: 'CBSAccountEdit',
                          params: { id: item.id },
                        }" class="btn btn-info btn-sm m-2px">
                        <i class="fas fa-pencil-alt"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="p-4" v-if="_.isEmpty(list.data)">
              <div class="alert alert-default-warning" role="alert">No data found!</div>
            </div>
          </div>
          <div class="card-footer">
            <vue-pagination :pagination="list" v-if="list.total > list.per_page" @paginate="getList()"></vue-pagination>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import VuePagination from '@/components/partials/PaginationComponent';
import { CBSAccountingApi } from '@/services/api';

export default {
  name: 'AccountList',
  components: {
    VuePagination
  },
  data: () => ({
    list: {
      total: 0,
      per_page: 10,
      from: 1,
      to: 0,
      current_page: 1
    },
    actionView: true,
    actionEdit: true,
    actionDelete: false,
    showFilter: false,
    filter: {},
    dateRange: '',
    pre: [],
    is_loading: false,
    empty: false,
    empty_message: ''
  }),
  mounted: function () {
    this.getList()
  },
  methods: {
    getList: function () {
      this.is_loading = true
      CBSAccountingApi.getAccount(this.list.current_page, this.filter, this.list.per_page)
        .then((items) => {
          this.list = items
        })
        .finally(() => {
          this.is_loading = false
        });
    },
    resetFilter () {
      this.list.current_page = 1
      this.filter = {}
      this.getList()
    }
  }
}
</script>

